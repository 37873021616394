import { Reducer, AnyAction } from 'redux'
import moment from 'moment'
import { actionTypes as authActionTypes } from '../authorisation/authorisation.actions'

import { getDefaultState } from '../state/state.model'
import { actionTypes } from './accounts.actions'
import { AccountsState } from './accounts.model'

import { accountsExpiresAtConfig } from '../config'
import { orderAccountsByProduct } from '../../common/accounts-helper'

export const initialState: AccountsState = {
  clientID: null,
  clientTotal: 0,
  accounts: [],
  customMessageAccounts: [],
  notifications: [],
  switchAccounts: [],
  directDebitAccounts: [],
  expiresAt: null,
  noAccountsMessage: null,
  ...getDefaultState(),
  isLoading: true,
}

export const accountsReducer: Reducer<AccountsState> = (
  state: AccountsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case actionTypes.ACCOUNTS_OVERVIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null,
        noAccountsMessage: null,
      }

    case actionTypes.ACCOUNTS_OVERVIEW_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        accounts: orderAccountsByProduct(action.payload.accounts),
        isLoading: false,
        expiresAt: moment()
          .add(accountsExpiresAtConfig.value, accountsExpiresAtConfig.unit)
          .format(),
        noAccountsMessage: null,
      }
    case actionTypes.ACCOUNTS_OVERVIEW_REQUEST_SKIP:
      return { ...state }
    case actionTypes.ACCOUNTS_OVERVIEW_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorMessage: action.payload,
        noAccountsMessage: null,
      }
    case actionTypes.ACCOUNTS_OVERVIEW_REQUEST_NO_ACCOUNTS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        noAccountsMessage: action.payload,
      }
    case actionTypes.ACCOUNTS_OVERVIEW_RENAME_SUCCESS:
      return {
        ...state,
        accounts: state.accounts.map((account) => {
          if (account.accountNumber === action.payload.accountNumber) {
            account.accountName = action.payload.accountName
          }
          return account
        }),
      }
    // Logging out resets the accounts list
    case authActionTypes.DEAUTHORISATION_REQUEST_SUCCESS:
      return initialState
    default:
      return state
  }
}
