import {
  ControlledAmountSelectInput,
  ControlledButtonGroup,
  ControlledRadioMenu,
} from '../../shared-components/form'
import {
  ContributionFrequency,
  EmploymentStatus,
  RetirementProjectorAboutYouInputs,
  RetirementProjectorState,
} from './RetirementProjector.types'
import { useFormik } from 'formik'
import { schemaValidation } from '../../utils'
import { retirementProjectorAboutYouForm } from './RetirementProjectorAboutYouForm.schema'

import './RetirementProjectorAboutYou.scss'
import useToggle from '../../hooks/use-toggle'
import ContactUsModal from '../../components/contact-us-modal'

import { Button } from '../../components/clickable/button/Button'
import { ReactComponent as IconHelp } from '../../assets/images/icons/icon_help.svg'
import Link from '../../components/clickable/link/Link'
import { useEffect, useMemo, useState } from 'react'
import { ProgressBarContextProvider } from '../../hooks/useProgressBar'
import IPQ from '../../components/ipq/IPQ'
import { RetirementProjectorFundOption } from './RetirementProjectorFundOption'
import { Account } from '../../redux/accounts/accounts.model'
import { isCashPlusLegacy, isMixMandate } from '../../common/accounts-helper'
import { castToInvestmentOption } from '../../utils/investmentOption'
import { Fund } from '../../components/switch-contentful-modal/SwitchContentfulModal.types'
import { Product } from '../../common/product-helper'
import InvestmentOptionInfoModal from '../../components/investment-option-info/InvestmentOptionInfoModal'

export type RetirementProjectorAboutYouProps = {
  currentAccount: Account
  activeFunds: Fund[]
  handleSubmit: (input: RetirementProjectorAboutYouInputs) => void
  contentfulProduct: Product
  state: RetirementProjectorState
}

export const RetirementProjectorAboutYou = (
  props: RetirementProjectorAboutYouProps
) => {
  const {
    currentAccount,
    activeFunds,
    handleSubmit,
    contentfulProduct,
    state,
  } = props
  const { toggle, toggleOn, toggleOff } = useToggle(false)
  const [IPQButtonOpen, setIPQButtonOpen] = useState(false)
  const [showFundSelection, setShowFundSelection] = useState(false)
  const [investmentOptionInfoFund, setInvestmentOptionInfoFund] = useState<
    Fund
  >(null)

  const form = useFormik<RetirementProjectorAboutYouInputs>({
    initialValues: state.aboutYou,
    validate: schemaValidation<RetirementProjectorAboutYouInputs>(
      retirementProjectorAboutYouForm
    ),
    onSubmit: () => {
      handleSubmit(form.values)
    },
    enableReinitialize: true,
  })

  const investmentOptionInfoModal = useMemo(() => {
    return castToInvestmentOption(null, investmentOptionInfoFund)
  }, [investmentOptionInfoFund])

  useEffect(() => {
    const canSelectFund =
      isMixMandate(currentAccount) || isCashPlusLegacy(currentAccount)
    setShowFundSelection(canSelectFund)

    if (!canSelectFund) {
      // if the account is not a mix mandate and it is not a CashPlus fund,
      // it must have only one fund, which is one of the active funds
      // Here it is to find the matching active fund
      const kiwiSaverFund = currentAccount?.funds && currentAccount.funds[0]
      const fund = activeFunds.find(
        (fund) => fund.telCode === kiwiSaverFund?.assetCode
      )
      // We manually set the fund value here to pass the `required` validation on this field
      form.setFieldValue('fund', fund)
    }
  }, [currentAccount, currentAccount?.funds, activeFunds]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="retirement-projector__about-you">
        <h4>A bit more about you</h4>
        <p className="retirement-projector__paragraph mb-md text-large">
          Let us know a bit more information so we can work out what your
          KiwiSaver balance and regular income might be when you&apos;re 65.
        </p>
        <div className="retirement-projector__about-you__form section-padding">
          <ol>
            <li>
              <p className="text-regular text-bold">
                Your first home withdrawal
              </p>
              <div className="retirement-projector__about-you__section">
                <ControlledRadioMenu
                  form={form}
                  className="retirement-projector__about-you__radio-group"
                  name="withdrawForFirstHome"
                  label="Are you planning on using your KiwiSaver investment towards your first home?"
                  labelSize="sm"
                  direction="horizontal"
                  size="small"
                  options={[
                    { label: 'Yes', value: true },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                />
              </div>
            </li>
            <hr />

            {form.values?.withdrawForFirstHome === false ? (
              <>
                <li>
                  <p className="text-regular text-bold">
                    Your employment details
                  </p>
                  <div className="retirement-projector__about-you__section">
                    <ControlledRadioMenu
                      form={form}
                      className="retirement-projector__about-you__radio-group"
                      name="employmentStatus"
                      label="What is your employment status?"
                      labelSize="sm"
                      direction="horizontal"
                      size="small"
                      options={[
                        {
                          label: 'Employed',
                          value: EmploymentStatus.EMPLOYED,
                        },
                        {
                          label: 'Self-Employed',
                          value: EmploymentStatus.SELF_EMPLOYED,
                        },
                        {
                          label: 'Not in paid employment',
                          value: EmploymentStatus.NOT_IN_PAID_EMPLOYMENT,
                        },
                      ]}
                    />
                    {form.values.employmentStatus ===
                      EmploymentStatus.EMPLOYED && (
                      <div className="retirement-projector__grid mt-sm">
                        <ControlledAmountSelectInput
                          form={form}
                          amountName="salary"
                          selectName="salaryFrequency"
                          options={[
                            {
                              label: 'Weekly',
                              value: ContributionFrequency.WEEKLY,
                            },
                            {
                              label: 'Fortnightly',
                              value: ContributionFrequency.FORTNIGHTLY,
                            },
                            {
                              label: 'Monthly',
                              value: ContributionFrequency.MONTHLY,
                            },
                            {
                              label: 'Yearly',
                              value: ContributionFrequency.YEARLY,
                            },
                          ]}
                          label="Salary or wages (before tax)"
                          labelSize="sm"
                          className="retirement-projector__about-you__input"
                        />
                      </div>
                    )}
                  </div>
                </li>
                <hr className="mt-lg" />
                <li>
                  <p className="text-regular text-bold">
                    Your current KiwiSaver contributions
                  </p>
                  <div className="retirement-projector__grid">
                    {form.values.employmentStatus ===
                      EmploymentStatus.EMPLOYED && (
                      <>
                        <ControlledButtonGroup
                          form={form}
                          label="Your contributions"
                          labelSize="sm"
                          name="contributionRate"
                          options={[
                            { label: '3%', value: 3 },
                            { label: '4%', value: 4 },
                            { label: '6%', value: 6 },
                            { label: '8%', value: 8 },
                            { label: '10%', value: 10 },
                          ]}
                        />
                        <ControlledButtonGroup
                          form={form}
                          label="Employer contributions"
                          labelSize="sm"
                          name="employerContributionRate"
                          options={[
                            { label: '3%', value: 3 },
                            { label: '4%', value: 4 },
                            { label: '6%', value: 6 },
                            { label: '8%', value: 8 },
                          ]}
                        />
                      </>
                    )}
                    <ControlledAmountSelectInput
                      form={form}
                      amountName="voluntaryContribution"
                      selectName="voluntaryContributionFrequency"
                      options={[
                        {
                          label: 'Weekly',
                          value: ContributionFrequency.WEEKLY,
                        },
                        {
                          label: 'Fortnightly',
                          value: ContributionFrequency.FORTNIGHTLY,
                        },
                        {
                          label: 'Monthly',
                          value: ContributionFrequency.MONTHLY,
                        },
                        {
                          label: 'Yearly',
                          value: ContributionFrequency.YEARLY,
                        },
                      ]}
                      label="Voluntary contributions"
                      labelSize="sm"
                      className="retirement-projector__more-about-you__input"
                    />
                  </div>
                </li>
                {showFundSelection && (
                  <>
                    <hr className="mt-lg" />
                    <li>
                      <div>
                        <p className="text-regular text-bold">
                          Select your investment fund{' '}
                        </p>
                        <p className="text-small">
                          Choose the investment fund that best matches your
                          existing investment fund
                        </p>
                        <div className="retirement-projector__grid">
                          {(activeFunds || []).map((fund) => (
                            <RetirementProjectorFundOption
                              key={fund.shortName}
                              fund={fund}
                              onSelect={() => {
                                form.setFieldValue('fund', fund)
                              }}
                              onMoreInfoClick={setInvestmentOptionInfoFund}
                              selected={
                                form.values.fund?.telCode === fund.telCode
                              }
                              error={!!form.errors.fund && !!form.touched.fund}
                            />
                          ))}
                        </div>
                        {form.errors.fund && form.touched.fund && (
                          <p className="retirement-projector__about-you__fund-selection--error">
                            {form.errors.fund as any}
                          </p>
                        )}
                        <Button
                          variant="link"
                          className="mt-md text-underline"
                          onClick={() => {
                            setIPQButtonOpen(true)
                          }}
                        >
                          Find out which fund suits you
                        </Button>
                      </div>
                    </li>
                  </>
                )}
                <hr className="mt-lg mb-lg" />

                <Button
                  className="ml-auto"
                  variant="filled"
                  color="primary"
                  disabled={!state.dalId}
                  onClick={() => {
                    form.handleSubmit()
                  }}
                >
                  View your projection
                </Button>
              </>
            ) : (
              <>
                <p className="text-small">
                  If you’re planning to use your KiwiSaver money towards your
                  first home, this Retirement Projector isn’t suitable for you.
                </p>
                <p className="text-small mb-md">
                  To see how your KiwiSaver account could help you buy your
                  first home, you can chat to our friendly team.
                </p>

                <Button
                  className="ml-auto"
                  variant="filled"
                  color="primary"
                  onClick={toggleOn}
                >
                  Contact us
                </Button>
              </>
            )}
          </ol>
        </div>
      </div>

      <Link
        to={`${process.env.REACT_APP_WEBSITE_URL}/retirement-projector-assumptions`}
        target="_blank"
        iconLeft={() => <IconHelp />}
        className="my-md"
      >
        How this retirement projector works
      </Link>

      <ContactUsModal open={toggle} onClose={toggleOff} />
      {IPQButtonOpen && (
        <ProgressBarContextProvider>
          <IPQ
            onClose={() => setIPQButtonOpen(false)}
            account={currentAccount}
          />
        </ProgressBarContextProvider>
      )}
      {investmentOptionInfoFund && (
        <InvestmentOptionInfoModal
          onClose={() => setInvestmentOptionInfoFund(null)}
          product={contentfulProduct}
          investmentOption={investmentOptionInfoModal}
        />
      )}
    </>
  )
}
