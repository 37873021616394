import Modal from '../modal/Modal'
import {
  hasWriteAuthorisation,
  isInvestmentFundsAccount,
  isKiwiSaverPlanAccount,
} from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import { useEffect, useState } from 'react'
import WithdrawFundsIntro from './WithdrawFundsIntro'
import WithdrawFundsForm from './WithdrawFundsForm'
import FFKPWithdrawFundsContent from './FFKPWithdrawFundsPDFContent'
import FFIFWithdrawFundsContent from './FFIFWithdrawFundsPDFContent'
import { UserState } from '../../redux/user/user.model'
import { isAdult } from '../../common/user-helper'
import WithdrawFundsConfirmation from './WithdrawFundsConfirmation'

export interface WithdrawModalProps {
  account: Account
  user: UserState
  onClose: () => void
}

enum WITHDRAW_MODAL_STEPS {
  INTRO,
  FORM,
  CONFIRMATION,
  PDF_WITHDRAWAL,
}

const WithdrawFundsModal = ({ account, user, onClose }: WithdrawModalProps) => {
  const initialStep = isKiwiSaverPlanAccount(account)
    ? WITHDRAW_MODAL_STEPS.INTRO
    : WITHDRAW_MODAL_STEPS.FORM
  const [step, setStep] = useState<number>(initialStep)

  const isOnlineWithdrawalFormEnabled =
    process.env.REACT_APP_FEATURE_ENABLE_ONLINE_WITHDRAW_MODAL === 'true'

  const showPdfModal =
    !isOnlineWithdrawalFormEnabled ||
    (isInvestmentFundsAccount(account) &&
      (!isAdult(user.birthDate) || !hasWriteAuthorisation(account)))

  const title = `Withdraw Funds${showPdfModal ? '' : '\u00B9'}`

  useEffect(() => {
    //If we haven't enabled the online withdrawals yet, or FFIF is under 18 || not an authorised actor we will show PDF.
    if (showPdfModal) {
      setStep(WITHDRAW_MODAL_STEPS.PDF_WITHDRAWAL)
    }
  }, [account, showPdfModal]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleStepNext = () => {
    switch (step) {
      case WITHDRAW_MODAL_STEPS.INTRO:
        return setStep(WITHDRAW_MODAL_STEPS.FORM)
      case WITHDRAW_MODAL_STEPS.FORM:
        return setStep(WITHDRAW_MODAL_STEPS.CONFIRMATION)
    }
  }

  const renderStep = () => {
    switch (step) {
      case WITHDRAW_MODAL_STEPS.FORM:
        return <WithdrawFundsForm />

      case WITHDRAW_MODAL_STEPS.CONFIRMATION:
        return (
          <WithdrawFundsConfirmation
            amount="12355"
            accountNumber={account.accountNumber}
            onDone={onClose}
          />
        )

      case WITHDRAW_MODAL_STEPS.INTRO:
      default:
        return <WithdrawFundsIntro onSubmit={handleStepNext} />
    }
  }

  const renderPDFModal = () => {
    if (isKiwiSaverPlanAccount(account)) {
      return <FFKPWithdrawFundsContent onClose={onClose} />
    }
    return <FFIFWithdrawFundsContent onClose={onClose} />
  }

  return (
    <Modal
      open
      title={title}
      subtitle={account.accountNumber}
      onClose={onClose}
      className="withdraw-funds-modal"
    >
      {showPdfModal ? renderPDFModal() : renderStep()}
    </Modal>
  )
}

export default WithdrawFundsModal
