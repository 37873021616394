import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { IPQFormValues, IPQQuestion, IPQQuestionnaireAnswer } from './ipq.model'
import { useProgressBarContext } from '../../hooks/useProgressBar'
import { Box } from '@material-ui/core'
import { Button } from '../clickable/button/Button'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'
import {
  RadioMenu,
  RadioMenuOption,
} from '../../shared-components/form/RadioMenu'

export interface IPQQuestionSectionProps {
  questions: IPQQuestion[] | null
  templateId?: number
  dalId?: string
  onBack: () => void
  handleNext: (values?: IPQFormValues) => void
}
const OMNIMAX_QUESTION_TYPES = {
  SELECT: '8',
  MULTI_CHOICE: "MultiChoice'",
}

const IPQInitialValues: IPQFormValues = {
  answers: [],
  templateId: 0,
}

const getQuestionValue = (
  answers: IPQQuestionnaireAnswer[],
  id: number
  // eslint-disable-next-line consistent-return
): RadioMenuOption<number> | undefined => {
  const Option = getOptionById(answers, id)
  if (!Option) {
    return null
  }
  return {
    label: Option.optionLabel,
    value: Option.optionId,
  }
}

// eslint-disable consistent-return
const getOptionById = (answers: IPQQuestionnaireAnswer[], id?: number) => {
  if (answers) {
    const optionObject: IPQQuestionnaireAnswer | undefined = answers.find(
      (answer: { questionId: number }) => answer?.questionId === id
    )
    return optionObject
  }
  return undefined
}

const IPQQuestionSection = ({
  questions,
  onBack,
  handleNext,
  templateId,
  dalId,
}: IPQQuestionSectionProps) => {
  const [showErrors, setShowErrors] = useState<boolean>(false)

  const { setProgress } = useProgressBarContext()

  const IPQForm = useFormik({
    initialValues: { ...IPQInitialValues, templateId },

    onSubmit: (values) => handleNext(values),
  })

  // eslint-disable-next-line max-len
  const checkQuestionsCompleted = (): boolean =>
    !!questions &&
    questions.every((question) =>
      getOptionById(IPQForm.values.answers, question.id)
    )

  useEffect(() => {
    setProgress(IPQForm.values.answers.length)
  }, [IPQForm.values.answers.length, setProgress])

  const updateAnswer = (
    v: { value: number; label: string },
    question: IPQQuestion
  ) => {
    const { values, setFieldValue } = IPQForm
    const answerIndex = values.answers.findIndex(
      (answer: IPQQuestionnaireAnswer) => answer.questionId === question.id
    )
    const IPQAnswers = [...values.answers]
    const answer = {
      questionId: question.id,
      questionLabel: question.text,
      optionId: v.value,
      optionLabel: v.label,
    }

    if (answerIndex > -1) {
      IPQAnswers[answerIndex] = answer
    } else {
      IPQAnswers.push(answer)
    }
    setFieldValue('answers', IPQAnswers)
  }

  const renderQuestion = (question: IPQQuestion) => {
    switch (question.type) {
      case OMNIMAX_QUESTION_TYPES.MULTI_CHOICE:
      default:
        return (
          <RadioMenu
            options={question.options.map((option) => {
              return {
                value: option.id,
                label: option.text,
              }
            })}
            direction="vertical"
            onSelect={(v) => {
              updateAnswer(v, question)
            }}
            value={getQuestionValue(IPQForm.values.answers, question.id)}
            name={'ipq__radio__menu'}
          />
        )
    }
  }

  const handleSubmit = () => {
    const isSectionCompleted = checkQuestionsCompleted()
    setShowErrors(!isSectionCompleted)
    if (!isSectionCompleted) {
      return
    }
    IPQForm.setSubmitting(true)
    IPQForm.handleSubmit()
  }

  return (
    <form>
      <ol className="question__list">
        {!questions ? (
          <SpinnerOnLoad className="my-md" isLoading centerSpinner={true} />
        ) : (
          <>
            {questions.map((question) => (
              <li key={`question-${question.id}`}>
                <Box className="pb-sm" display="flex" flexDirection="column">
                  <p
                    className="question__label my-0 py-0"
                    dangerouslySetInnerHTML={{
                      // eslint-disable-line react/no-danger
                      __html: question.text,
                    }}
                  />
                  {renderQuestion(question)}
                  {showErrors &&
                    !getOptionById(IPQForm.values.answers, question.id) && (
                      <p className="question__list-error">
                        Please select an option.
                      </p>
                    )}
                </Box>
              </li>
            ))}
          </>
        )}
      </ol>
      <div className="ipq__form__buttons">
        <Button
          variant="filled"
          color="primary"
          size="sm"
          type="button"
          disabled={IPQForm.isSubmitting || !dalId}
          onClick={handleSubmit}
        >
          Next
        </Button>

        <Button
          variant="link"
          color="primary"
          size="sm"
          onClick={() => onBack()}
        >
          Back
        </Button>
      </div>
    </form>
  )
}

export default IPQQuestionSection
