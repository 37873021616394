import React, { SyntheticEvent, useEffect, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import cn from 'classnames'
import { PirRate } from '../../redux/pir/pir.model'
import './ChangePIR.scss'

import {
  PIRChangeUnavailableMessage,
  WhatIsPIRLink,
  PIRChangeMessage,
} from './components'

// Fetches and displays the user's current PIR rate.
// Gets the current list of PIR codes from the API and caches it.
// Provides a save handler for saving changes to the user's PIR rate.
import { trackPIRChange } from '../../common/gtm-helper'

import { DispatchProps, StateProps } from './ChangePIR.container'
import {
  AuthorizationFormValues,
  useAuthorizedRequest,
} from '../authorization-modal/use-authorized-request'
import { Button } from '../clickable/button/Button'

export type Props = {
  className?: string
  handleBack?: () => void
}

type ChangePIRProps = Props & DispatchProps & StateProps

export const ChangePir = (props: ChangePIRProps) => {
  const { pirRates } = props
  const [selectedPirCode, setSelectedPirCode] = useState('')

  const [authElements, protectFunction, setModalOpen] = useAuthorizedRequest()

  useEffect(() => {
    props.getPirRate(props.userId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.hasPIRChanged) {
      setSelectedPirCode('')

      setTimeout(() => {
        props.resetPirSuccess()
      }, 5000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hasPIRChanged])

  const getPir = (code: string): PirRate => {
    let currentCode = code === 'PIR0' ? 'PIRD' : code
    let pirRateObject = props.pirRates.find(
      (value) => value.code === currentCode
    )

    return (
      pirRateObject || props.pirRates.find((value) => value.code === 'PIRD')
    )
  }

  const currentPirRate = getPir(props.currentPirRate?.code)
  const submit = (formValues: AuthorizationFormValues) => {
    // If no option selected or PIR0/PIRD is selected, then don't save
    if (
      !selectedPirCode ||
      selectedPirCode === 'PIR0' ||
      selectedPirCode === 'PIRD'
    ) {
      return
    }

    const newPir = getPir(selectedPirCode)
    trackPIRChange(currentPirRate, newPir)

    props.savePirRate({ pirRate: selectedPirCode, ...formValues }, props.userId)
    setModalOpen(false)
  }

  const handleReset = () => {
    setSelectedPirCode('')
  }

  const { className } = props
  return (
    <Paper
      elevation={0}
      className={cn('pir-details-content my-details-card', {
        [`${className}`]: !!className,
      })}
    >
      {authElements}
      <Typography component="h1" variant="h6" className="mobile-sm-only mb-md">
        Prescribed Investor Rate (PIR)
        <IconButton
          color="default"
          className="back-button"
          onClick={() => props.handleBack()}
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
      </Typography>
      <Typography component="h1" variant="h5" className="mobile-sm-hidden">
        Prescribed Investor Rate (PIR)
      </Typography>

      {props.hasOwnAccount && (
        <PIRChangeMessage
          isLoading={props.isLoading}
          isSaving={props.isSaving}
          hasPIRChanged={props.hasPIRChanged}
          hasError={props.hasError}
          errorMessage={props.errorMessage}
        />
      )}
      {props.hasCompanyAccount && <PIRChangeUnavailableMessage />}

      <WhatIsPIRLink />

      {props.hasOwnAccount && (
        <>
          <div className="pir-options-menu-wrapper">
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel htmlFor="pir-select-label">PIR</InputLabel>
              <Select
                native
                fullWidth
                label="PIR"
                labelId="pir-select-label"
                variant="outlined"
                value={
                  selectedPirCode || !currentPirRate
                    ? selectedPirCode
                    : currentPirRate.code
                }
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setSelectedPirCode(event.target.value as string)
                }}
                inputProps={{
                  name: 'pir',
                  id: 'pir-select-label',
                }}
              >
                {pirRates
                  .filter(
                    (pir) =>
                      // Remove PIR0 as an option, otherwise it shows up as an option in Safari (hidden property and style display: none don't work).
                      pir.code !== 'PIR0' &&
                      (pir.code !== 'PIRD' || currentPirRate.code === 'PIRD')
                  )
                  .map((pir, index: number) => {
                    const shouldDisable = pir.code === 'PIRD'
                    return (
                      <option
                        key={index}
                        value={pir.code}
                        disabled={shouldDisable}
                      >
                        {pir.shortDescription.replace('PIR ', '')}
                      </option>
                    )
                  })}
              </Select>
            </FormControl>
          </div>

          <div className="action-buttons">
            <Button
              disabled={
                props.isLoading ||
                props.isSaving ||
                !selectedPirCode ||
                selectedPirCode === 'PIR0' ||
                selectedPirCode === 'PIRD'
              }
              color="primary"
              onClick={protectFunction((e: SyntheticEvent) => {
                e.preventDefault()
                return submit
              })}
            >
              Confirm Update
            </Button>
            <Button
              variant="link"
              disabled={props.isLoading || props.isSaving}
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </>
      )}
    </Paper>
  )
}

export default ChangePir
