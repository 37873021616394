import { ReactNode } from 'react'
import { CardActions } from '@material-ui/core'
import cn from 'classnames'
import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import './Notification.scss'

type NotificationProps = {
  className?: string
  type?: 'info' | 'warning' | 'error' | 'success'
  actions?: ReactNode
  onClose?: () => void
  backgroundColor?: 'white' | 'grey' | 'orange'
  children?: any
}

export const Notification: React.FC<NotificationProps> = ({
  className,
  children,
  type = 'info',
  actions,
  onClose,
  backgroundColor = 'grey',
}) => {
  const isDismissable = !!onClose
  const RenderIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorIcon className="notification__icon" />
      case 'success':
        return <CheckCircleIcon className="notification__icon" />
      case 'info':
        return <InfoIcon className="notification__icon" />
      case 'warning':
        return <ErrorIcon className="notification__icon" />
      default:
        return <InfoIcon className="notification__icon" />
    }
  }

  return (
    <div
      className={cn(
        'notification',
        `notification--${type} notification--${backgroundColor}`,
        {
          [`${className}`]: !!className,
        }
      )}
    >
      {RenderIcon()}
      <div className="notification__content p-0 ml-xs">
        {children}
        {!!actions && <CardActions>{actions}</CardActions>}
      </div>
      {isDismissable && (
        <CloseIcon className="notification__close ml-lg" onClick={onClose} />
      )}
    </div>
  )
}
