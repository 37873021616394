import React from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

let bugsnagClient
let ErrorBoundary: any

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_API_KEY)
  bugsnagClient.use(bugsnagReact, React)
  ErrorBoundary = bugsnagClient.getPlugin('react')
} else {
  ErrorBoundary = (props: any) => props.children
}

export default bugsnagClient
export { ErrorBoundary }
