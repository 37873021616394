import * as React from 'react'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import './InfoMessage.scss'

type InfoMessageProps = {
  children?: any
}

/**
 * @deprecated Don't use this and instead use Notification type="info"
 */
export default class InfoMessage extends React.Component<InfoMessageProps> {
  render() {
    return (
      <div className="info-message-component">
        <span className="icon">
          <InfoOutlinedIcon />
        </span>
        <span className="message">{this.props.children}</span>
      </div>
    )
  }
}
