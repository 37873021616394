import * as React from 'react'
import cn from 'classnames'
import { ReactComponent as IconAccounts } from '../assets/images/icons/icon_accounts.svg'
import { ReactComponent as IconCalculator } from '../assets/images/icons/icon_calculator.svg'
import { ReactComponent as IconDetails } from '../assets/images/icons/icon_details.svg'
import { ReactComponent as IconDownload } from '../assets/images/icons/icon_download.svg'
import { ReactComponent as IconLinkOut } from '../assets/images/icons/icon_link-out.svg'
import { ReactComponent as IconLogout } from '../assets/images/icons/icon_logout.svg'
import { ReactComponent as IconMinus } from '../assets/images/icons/icon_minus.svg'
import { ReactComponent as IconPlus } from '../assets/images/icons/icon_plus.svg'
import { ReactComponent as IconRefresh } from '../assets/images/icons/icon_refresh.svg'
import { ReactComponent as IconReports } from '../assets/images/icons/icon_reports.svg'
import { ReactComponent as IconError } from '../assets/images/icons/icon_error.svg'
import './CustomIcon.scss'

// Add new icons to this type.
type IconName =
  | 'accounts'
  | 'calculator'
  | 'details'
  | 'download'
  | 'link-out'
  | 'logout'
  | 'minus'
  | 'plus'
  | 'refresh'
  | 'reports'
  | 'error'

const ICONS: {
  [key in IconName]: React.ReactNode | React.FunctionComponent
} = {
  accounts: IconAccounts,
  calculator: IconCalculator,
  details: IconDetails,
  download: IconDownload,
  reports: IconReports,
  logout: IconLogout,
  minus: IconMinus,
  plus: IconPlus,
  refresh: IconRefresh,
  'link-out': IconLinkOut,
  error: IconError,
}

const getIconSvg = (iconName: string) => {
  const icon = ICONS[iconName]
  if (icon) {
    return icon
  }

  console.log(`No existing icon for ${icon}`)
  return null
}

type CustomIconProps = {
  icon: IconName
  light?: boolean
  lake?: boolean
  grey?: boolean
  className?: string
} & React.SVGProps<SVGSVGElement>

const CustomIcon = ({
  icon,
  light,
  lake,
  grey,
  className,
  ...rest
}: CustomIconProps) => {
  const Icon = getIconSvg(icon)
  return (
    <Icon
      className={cn(`custom-icon icon-${icon}`, {
        light: light,
        lake: lake,
        grey: grey,
        [`${className}`]: !!className,
      })}
      {...rest}
    />
  )
}

export default CustomIcon
