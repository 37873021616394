import React, { useEffect } from 'react'

import AccountTransactionsHistory from '../account-transactions-history/AccountTransactionsHistory'
import { AccountTransactionFilter } from '../account-transaction-filter/AccountTransactionFilter'
import {
  Transaction,
  TransactionFilters,
} from '../../redux/account-transactions/account-transactions.model'

import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'
import TabPanel from '../tab-panel/TabPanel'

import './AccountTransactionsTab.scss'
import { AppState } from '../../redux/app-state'
import { bindActionCreators, Dispatch } from 'redux'
import {
  AccountTransactionsActions,
  AccountTransactionsRequestAction,
  TransactionsPayload,
  UpdateAccountTransactionsFiltersAction,
} from '../../redux/account-transactions/account-transactions.actions'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import Alert from 'react-s-alert'
import { getAccountByID } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'

interface AccountPerformanceTabProps {
  productName: string
  accountNumber: string
  currentAccount: Account
  isLoading: boolean
  filters: TransactionFilters
  isLoadingFirstTransaction: boolean
  hasError: boolean
  errorMessage: string
  transactions: Transaction[]
  showLoadMore: boolean
  getTransactions: typeof AccountTransactionsRequestAction
  updateFilters: typeof UpdateAccountTransactionsFiltersAction
  loadMoreTransactions: () => void
  userId: string
  params: {
    id: string
  }
}

export function AccountTransactionsTab(props: AccountPerformanceTabProps) {
  const { getTransactions, userId, params, filters } = props

  useEffect(() => {
    getTransactions({
      userId: userId,
      accountId: params.id,
      offset: 0,
      shouldReturnAll: false,
      filters: filters,
      isCSV: false,
    })
  }, [userId, filters, params.id, getTransactions])

  useEffect(() => {
    if (props.hasError) {
      Alert.error(props.errorMessage, { timeout: 'none' })
    }
  }, [props.hasError, props.errorMessage])

  const loadMoreTransactions = () => {
    const lastTransaction = props.transactions.length - 1
    const offset: number = parseInt(
      props.transactions[lastTransaction].transactionEventId,
      10
    )
    props.getTransactions({
      userId: props.userId,
      accountId: props.params.id,
      offset,
      shouldReturnAll: false,
      filters: props.filters,
      isCSV: false,
    })
  }

  const updateFilters = (filters: TransactionFilters) => {
    // Load all transactions if a filter is selected
    let shouldReturnAll = false
    if (filters.dateType !== 'all') {
      shouldReturnAll = true
    }

    let payload = {
      userId: props.userId,
      accountId: props.params.id,
      shouldReturnAll: shouldReturnAll,
      filters: filters,
    } as TransactionsPayload

    props.updateFilters(payload)
  }

  return (
    <TabPanel value={1} index={1}>
      <div className="account-transactions-tab-component">
        <AccountTransactionFilter
          filters={props.filters}
          updateFilters={updateFilters}
          transactions={props.transactions}
          productName={props.productName}
          accountNumber={props.params.id}
          productExternalRef={props.currentAccount.productExternalRef}
        />
        <SpinnerOnLoad
          isLoading={props.isLoadingFirstTransaction}
          centerSpinner={true}
          showMessageAfter={5000}
        >
          <AccountTransactionsHistory
            isLoading={props.isLoading}
            isLoadingFirstTransaction={props.isLoadingFirstTransaction}
            hasError={props.hasError}
            transactions={props.transactions}
            filters={props.filters}
            updateFilters={updateFilters}
            showLoadMore={props.showLoadMore}
            loadMoreTransactions={loadMoreTransactions}
            productName={props.productName}
            accountNumber={props.params.id}
          />
        </SpinnerOnLoad>
      </div>
    </TabPanel>
  )
}

const mapStateToProps = (
  state: AppState,
  props: AccountPerformanceTabProps
) => {
  return {
    isLoading: state.accountTransactions.isLoading,
    isLoadingFirstTransaction:
      state.accountTransactions.isLoading &&
      state.accountTransactions.transactions.length === 0,
    hasError: state.accountTransactions.hasError,
    errorMessage: state.accountTransactions.errorMessage,
    productName: state.accountTransactions.productName,
    transactions: state.accountTransactions.transactions,
    filters: state.accountTransactions.filters,
    showLoadMore:
      state.accountTransactions.hasMoreTransactions ||
      state.accountTransactions.isLoading ||
      state.accountTransactions.hasError,
    userId: state.user.userid,
    currentAccount: getAccountByID(
      props.params.id.toString(),
      state.accounts.accounts
    ),
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<AccountTransactionsActions>
) => ({
  getTransactions: bindActionCreators(
    AccountTransactionsRequestAction,
    dispatch
  ),
  updateFilters: bindActionCreators(
    UpdateAccountTransactionsFiltersAction,
    dispatch
  ),
  nextStep: (path: string) => dispatch(routerActions.push(path)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(AccountTransactionsTab)
