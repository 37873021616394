import './AccountDetailsReturn.scss'
import CurrencyDifference from '../currency/CurrencyDifference'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import PerformanceDifference from '../performance-difference/PerformanceDifference'

type AccountDetailsReturnProps = {
  value: number
  type: 'CURRENCY' | 'PERCENTAGE'
  tooltip?: string
  description: string
  label: string
}

export const AccountDetailsReturn = ({
  value,
  type,
  tooltip,
  description,
  label,
}: AccountDetailsReturnProps) => {
  const renderReturn = () => {
    if (!!tooltip) {
      return (
        <Tooltip title={tooltip} disableFocusListener enterTouchDelay={200}>
          <div className="tooltip">
            <span className="tooltip-label">
              {type === 'CURRENCY' ? (
                <CurrencyDifference value={value} />
              ) : (
                <PerformanceDifference value={value} />
              )}
            </span>
            <span className="tooltip-icon ml-xs flex-row-center">
              <InfoIcon />
            </span>
          </div>
        </Tooltip>
      )
    }

    return type === 'CURRENCY' ? (
      <CurrencyDifference value={value} />
    ) : (
      <PerformanceDifference value={value} />
    )
  }
  return (
    <div className="account-details-return">
      <div className="flex-column">
        <p className="return-label account-details-information__returns__label">
          {label}
        </p>
        <p className="text-large return-description">{description}</p>
      </div>
      <div className="return-value flex-row">{renderReturn()}</div>
    </div>
  )
}
