import moment from 'moment'
import isNumber from 'lodash/isNumber'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import CurrencyDifference from '../currency/CurrencyDifference'
import format from 'date-fns/format'
import startOfYear from 'date-fns/startOfYear/index'
import PerformanceDifference from '../performance-difference/PerformanceDifference'
import './AccountCardReturns.scss'

export type AccountCardReturnsProps = {
  marketEarnings: number
  commenceDate: string
  yearToDatePercentageReturn?: number
  annualisedPercentageReturn?: number
}

export const AccountCardReturns = ({
  marketEarnings,
  commenceDate,
  annualisedPercentageReturn,
  yearToDatePercentageReturn,
}: AccountCardReturnsProps) => {
  if (!!yearToDatePercentageReturn) {
    return (
      <div className="account-card-returns flex-row-justify-content-space-between">
        <div className="flex-column">
          <span className="return-label mb-2xs">Year to date return</span>
          <span className="return-description">
            Since {format(startOfYear(new Date()), 'd MMM yyyy')}
          </span>
        </div>
        <PerformanceDifference value={yearToDatePercentageReturn} />
      </div>
    )
  }

  if (!!annualisedPercentageReturn) {
    return (
      <div className="account-card-returns flex-row-justify-content-space-between">
        <div className="flex-column">
          <span className="return-label mb-2xs">Average return</span>
          <span className="return-description">
            Since your first investment
          </span>
        </div>
        <PerformanceDifference value={annualisedPercentageReturn} />
      </div>
    )
  }

  return (
    <>
      <Grid item>
        <Typography className="account-card__returns__label">
          Since inception return
        </Typography>
        {isNumber(marketEarnings) ? (
          <CurrencyDifference value={marketEarnings} />
        ) : (
          <div className="account-card__returns__loading-shimmer" />
        )}
      </Grid>
      <Grid item>
        <Typography>After fees and tax</Typography>
        {!!commenceDate && (
          <Typography>
            {'since ' + moment(commenceDate).format('DD MMM YYYY')}
          </Typography>
        )}
      </Grid>
    </>
  )
}
