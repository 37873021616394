import React from 'react'
import { Link } from 'react-router'
import MuiLink from '@material-ui/core/Link'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Fade from '@material-ui/core/Fade'
import CustomIcon from '../../common/CustomIcon'
import {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
} from '../contentful-terms-and-conditions'
import { trackUserId } from '../../common/gtm-helper'
import { useAuth0 } from '@auth0/auth0-react'
import { Divider } from '@material-ui/core'

import './NavigationMobile.scss'

export type StateProps = {
  showInvestmentTools?: boolean
}

type OwnProps = {
  activeMenu: string
}

type NavigationMobileProps = StateProps & OwnProps

const NavigationMobile = (props: NavigationMobileProps) => {
  const { logout } = useAuth0()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logoutWithRedirect = () => {
    trackUserId(null)
    logout({
      returnTo: `${window.location.origin}/logged-out`,
    })
  }

  const getCurrentValue = () => {
    switch (props.activeMenu) {
      case 'accounts':
      case 'account-details':
      case 'account-funds':
      case 'account-transactions':
        return 0
      case 'reports':
        return 1
      case 'details':
        return 2
      case 'investment':
      case 'terms':
      case 'privacy':
      default:
        return 3
    }
  }

  return (
    <>
      <BottomNavigation showLabels value={getCurrentValue()}>
        <BottomNavigationAction
          disableRipple
          label="Accounts"
          icon={
            <CustomIcon icon="accounts" className="MuiSvgIcon-root" light />
          }
          component={Link}
          to="/accounts"
        />
        <BottomNavigationAction
          disableRipple
          label="Reports"
          icon={<CustomIcon icon="reports" className="MuiSvgIcon-root" light />}
          component={Link}
          to="/reports"
        />
        <BottomNavigationAction
          disableRipple
          label="Details"
          icon={<CustomIcon icon="details" className="MuiSvgIcon-root" light />}
          component={Link}
          to="/details"
        />
        <BottomNavigationAction
          disableRipple
          label="More"
          icon={<MoreHorizIcon />}
          aria-haspopup="true"
          onClick={handleClick}
        />
      </BottomNavigation>

      <Menu
        anchorEl={anchorEl}
        keepMounted={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="navigation-mobile"
        elevation={0}
      >
        {props.showInvestmentTools && (
          <>
            <MenuItem disableRipple onClick={handleClose}>
              <Link className="navigation-mobile-link" to="/investment-tools">
                <CustomIcon
                  icon="calculator"
                  className="MuiSvgIcon-root"
                  grey
                />
                Investment tools
              </Link>
            </MenuItem>
            <Divider variant="middle" />
          </>
        )}

        <MenuItem disableRipple>
          <TermsAndConditionsLink
            className="navigation-mobile-link"
            icon={
              <CustomIcon icon="reports" className="MuiSvgIcon-root" grey />
            }
          />
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem disableRipple>
          <PrivacyPolicyLink
            className="navigation-mobile-link"
            icon={
              <CustomIcon icon="reports" className="MuiSvgIcon-root" grey />
            }
          />
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem disableRipple>
          <MuiLink
            onClick={logoutWithRedirect}
            className="navigation-mobile-link log-out-link"
            underline="none"
          >
            <CustomIcon icon="logout" className="MuiSvgIcon-root" grey /> Log
            out
          </MuiLink>
        </MenuItem>
      </Menu>
    </>
  )
}

export default NavigationMobile
