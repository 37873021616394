import { useMemo } from 'react'
import { Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import format from 'date-fns/format'
import {
  IInvestmentOption,
  getInvestmentOptionAssetMix,
} from '../../utils/investmentOption'
import { RiskTooltip } from '../risk-tooltip-content/RiskTooltip'
import { StrategyRiskIndicator } from '../strategy-risk-indicator/StrategyRiskIndicator'
import InvestmentMixAssets, {
  GROWTH_ASSETS_COLOR,
  INCOME_ASSETS_COLOR,
} from '../investment-mix/InvestmentMixAssets'
import { formatPercentage } from '../investment-mix/utils'
import { useInvestmentReturnSummary } from '../../api/returns/useInvestmentReturnSummary'
import differenceInYears from 'date-fns/differenceInYears'
import { Product } from '../../common/product-helper'

const NO_SUGGESTED_TIMEFRAME =
  'There is no minimum suggested timeframe for this fund.'

type InvestmentOptionsDetailsProps = {
  product?: Product
  investmentOption: IInvestmentOption
}

const InvestmentOptionDetails = ({
  product,
  investmentOption,
}: InvestmentOptionsDetailsProps) => {
  const { returnsSummary } = useInvestmentReturnSummary(
    product,
    investmentOption
  )

  const isOlderThan5Years = useMemo(
    () =>
      !!returnsSummary?.inceptionDate &&
      differenceInYears(new Date(), new Date(returnsSummary.inceptionDate)) >=
        5,
    [returnsSummary?.inceptionDate]
  )
  const annualisedReturn = useMemo(
    () =>
      isOlderThan5Years
        ? returnsSummary?.return5Years
        : returnsSummary?.returnSinceInception,
    [isOlderThan5Years, returnsSummary]
  )

  const suggestedTimeframeLowercase = investmentOption?.suggestedTimeframe?.toLowerCase()

  const { incomeAssetsPercentage, growthAssetsPercentage } = useMemo(
    () => getInvestmentOptionAssetMix(investmentOption),
    [investmentOption]
  )

  return (
    <>
      <Tooltip
        title={
          suggestedTimeframeLowercase
            ? `Suggested minimum timeframe for this investment is ${suggestedTimeframeLowercase}.`
            : NO_SUGGESTED_TIMEFRAME
        }
        disableFocusListener
        enterTouchDelay={200}
      >
        <div className="tooltip">
          <span className="tooltip-label">Suggested minimum timeframe</span>
          <span className="tooltip-icon">
            <InfoIcon />
          </span>
          <h6>{investmentOption.suggestedTimeframe ?? '--'}</h6>
        </div>
      </Tooltip>
      <hr />
      {!!annualisedReturn && (
        <Tooltip
          title={
            <>
              {!!investmentOption.inceptionDate && (
                <p>
                  * Inception Date:{' '}
                  {format(
                    new Date(investmentOption?.inceptionDate),
                    'dd/MM/yyyy'
                  )}
                </p>
              )}
              {returnsSummary?.priceDate && (
                <p>
                  * After fees and before tax returns as at{' '}
                  {format(new Date(returnsSummary.priceDate), 'dd/MM/yyyy')}.
                  Fund performance figures have been annualised where the
                  performance period is more than one year. Fund performance
                  figures do not include entry fees, exit fees or brokerage fees
                  or tax, but do include any tax credits applicable to the funds
                  since October 2007. Please note that past performance is not
                  necessarily indicative of future returns. Returns can be
                  positive or negative, and returns over different time periods
                  may vary. No returns are promised or guaranteed.
                </p>
              )}
            </>
          }
          disableFocusListener
          enterTouchDelay={200}
        >
          <div className="tooltip">
            <span className="tooltip-label">
              {' '}
              {`Annualised ${
                isOlderThan5Years ? '5 year return' : 'return since inception'
              }`}
            </span>
            <span className="tooltip-icon">
              <InfoIcon />
            </span>
            <h6>{formatPercentage(annualisedReturn)}</h6>
          </div>
        </Tooltip>
      )}
      <hr />
      <Tooltip
        title={`
            You will be charged fees for investing in Fisher Funds investments.
            Fees are deducted from your investment and will reduce your returns.
            If Fisher Funds invests in other funds, those funds may also charge fees.
          `}
      >
        <div className="tooltip">
          <span className="tooltip-label">Annual fund charge</span>
          <span className="tooltip-icon">
            <InfoIcon />
          </span>
          <h6>{investmentOption.annualFees}%</h6>
        </div>
      </Tooltip>
      <hr />
      <Tooltip
        title={
          !!investmentOption.riskLevel && (
            <>
              <RiskTooltip
                value={investmentOption.riskLevel as number}
                name={investmentOption.shortName}
              />
            </>
          )
        }
        disableFocusListener
        enterTouchDelay={200}
      >
        <div className="tooltip">
          <span className="tooltip-label">Risk indicator</span>
          <span className="tooltip-icon">
            <InfoIcon />
          </span>
          <StrategyRiskIndicator
            className="risk-indicator mt-md"
            minimum={1}
            maximum={7}
            value={investmentOption.riskLevel}
          />
        </div>
      </Tooltip>
      <hr />
      {incomeAssetsPercentage !== undefined &&
        growthAssetsPercentage !== undefined && (
          <InvestmentMixAssets
            assets={[
              {
                name: 'Income Assets',
                percentage: incomeAssetsPercentage,
                color: INCOME_ASSETS_COLOR,
              },
              {
                name: 'Growth Assets',
                percentage: growthAssetsPercentage,
                color: GROWTH_ASSETS_COLOR,
              },
            ]}
          />
        )}
    </>
  )
}

export default InvestmentOptionDetails
