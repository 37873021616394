import {
  Transaction,
  TransactionFilters,
} from '../../redux/account-transactions/account-transactions.model'
import Button from '@material-ui/core/Button'
import './AccountTransactionsHistory.scss'
import { Notification } from '../notification/Notification'
import AccountTransactionsTable from './AccountTransactionsTable'

interface AccountTransactionsHistoryProps {
  productName: string
  accountNumber: string
  isLoading: boolean
  filters: TransactionFilters
  isLoadingFirstTransaction: boolean
  hasError: boolean
  transactions: Transaction[]
  showLoadMore: boolean
  updateFilters: (filters: TransactionFilters) => void
  loadMoreTransactions: () => void
}

export function NoTransactionBlock() {
  return (
    <Notification>
      No transactions found. Please update your date period filter.
    </Notification>
  )
}

export function TransactionLoadMore(props: AccountTransactionsHistoryProps) {
  if (!props.showLoadMore) {
    return false
  }

  return (
    <div className="action-button">
      <Button
        disableRipple
        variant="contained"
        onClick={props.loadMoreTransactions}
        disabled={props.isLoading || props.hasError}
        color="primary"
      >
        {props.isLoading ? 'Loading...' : 'Load more'}
      </Button>
    </div>
  )
}

export default function AccountTransactionsHistory(
  props: AccountTransactionsHistoryProps
) {
  if (props.transactions && props.transactions.length === 0) {
    return NoTransactionBlock()
  }

  return (
    <div className="account-transactions-history-component">
      <div className="table-content-wrapper">
        <div className="table-content">
          <AccountTransactionsTable transactions={props.transactions} />
        </div>
        {TransactionLoadMore(props)}
      </div>
    </div>
  )
}
