import React, { ReactNode } from 'react'
import useToggle from '../../hooks/use-toggle'
import cn from 'classnames'
import ContentfulTermsAndConditionsModal from './ContentfulTermsAndConditionsModal'
import Link from '@material-ui/core/Link'

export const TermsAndConditionsLink = (
  props: Pick<ContentModalLinkProps, 'className' | 'icon'>
) => (
  <ContentModalLink
    contentfulEntryName="Digital Platforms - Terms and Conditions"
    title="Terms and Conditions"
    {...props}
  />
)

export const PrivacyPolicyLink = (
  props: Pick<ContentModalLinkProps, 'className' | 'icon'>
) => (
  <ContentModalLink
    contentfulEntryName="Privacy Policy"
    title="Privacy Policy"
    {...props}
  />
)

export type ContentModalLinkProps = {
  contentfulEntryName: string
  title: string
  className?: string
  icon?: ReactNode
}

const ContentModalLink = (props: ContentModalLinkProps) => {
  const { contentfulEntryName, title, className, icon } = props
  const { toggle, toggleOn, toggleOff } = useToggle(false)
  return (
    <>
      {toggle && (
        <ContentfulTermsAndConditionsModal
          contentfulEntryName={contentfulEntryName}
          title={title}
          open={toggle}
          onClose={toggleOff}
        />
      )}
      <Link
        className={cn({
          [`${className}`]: !!className,
        })}
        onClick={toggleOn}
        underline="none"
      >
        {icon}
        {title}
      </Link>
    </>
  )
}
