import * as React from 'react'
import cn from 'classnames'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'
import LoadingMessage from '../loading-message/LoadingMessage'

interface SpinnerOnLoadProps {
  className?: string
  isLoading: boolean
  centerSpinner?: boolean
  showMessageAfter?: number
  loadingMessage?: string
  children?: any
}

export default class SpinnerOnLoad extends React.Component<SpinnerOnLoadProps> {
  render() {
    const showMessage = !!this.props.showMessageAfter
    const { className } = this.props
    if (this.props.isLoading) {
      return (
        <div
          className={cn('spinner', 'my-md', {
            [`${className}`]: !!className,
          })}
        >
          <LoadingSpinner center={this.props.centerSpinner} />
          {showMessage && (
            <LoadingMessage
              wait={this.props.showMessageAfter}
              loadingMessage={this.props.loadingMessage}
            />
          )}
        </div>
      )
    }
    return this.props.children
  }
}
