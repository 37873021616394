import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { routerActions } from 'react-router-redux'
import cn from 'classnames'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import './AccountDetailsHeader.scss'
import {
  isKiwisaverAccount,
  isLifeSaverAccount,
  isFuturePlanAccount,
  getAccountByID,
  isInvestmentSeries,
  isManagedFundProductAccount,
  isApexProductAccount,
} from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import AccountDetailsMenu from '../account-details-menu/AccountDetailsMenu'
import AccountDetailsMenuFutureplan from '../account-details-menu-futureplan/AccountDetailsMenuFutureplan'
import AccountDetailsMenuKiwisaver from '../account-details-menu-kiwisaver/AccountDetailsMenuKiwisaver'
import AccountDetailsMenuLifesaver from '../account-details-menu-lifesaver/AccountDetailsMenuLifesaver'
import { AccountDetail } from '../../redux/account-details/account-details.model'
import { AppState } from '../../redux/app-state'
import { AccountDetailsActions } from '../../redux/account-details/account-details.actions'
import { AccountDetailsMenuInvestmentSeries } from '../account-details-menu-investment-series/AccountDetailsMenuInvestmentSeries'
import { SwitchNotificationsRequestAction } from '../../redux/switch-notifications/switch-notifications.actions'
import { AccountsOverviewFundsRequestAction } from '../../redux/accounts/accounts.actions'
import { AccountFundsRequestAction } from '../../redux/account-funds/account-funds.actions'
import InvestmentStrategySwitch from '../investment-strategy-switch/InvestmentStrategySwitch'
import {
  getAccountProductSlug,
  productHasStrategies,
  productSwitchIsPdfForm,
} from '../../common/product-helper'
import RenameAccountModal from '../rename-account/RenameAccountModal'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { UserData } from '../../redux/user/user.model'
import { ProductSlug } from '../../common/product-variables'
import { isApexSwitchEnabled } from '../../common/switch-helper'
import AccountDetailsMenuDepositMode from '../account-details-menu-deposit-mode/AccountDetailsMenuDepositMode'

export interface AccountDetailsHeaderProps {
  currentAccount?: Account
  details?: AccountDetail
  nextStep?: (path: string) => void
  accountId?: string
  hasError?: boolean
  errorMessage?: string
  switchNotifications?: Notification[]
  loadSwitchNotifications?: () => void
  user?: UserData
  authToken?: string
  fetchFunds?: (userId: string, nextAction: any, nextActionParams: any) => void
}

const ENABLE_RENAME = process.env.REACT_APP_ENABLE_RENAME_ACCOUNT === 'true'

function AccountDetailsHeader(props: AccountDetailsHeaderProps) {
  const { currentAccount: account, details, user, authToken } = props
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const productSlug = getAccountProductSlug(account) as ProductSlug
  const strategyName = details?.profile?.profileName
  const shouldShowStrategySwitch =
    !account.isDepositMode &&
    (!isApexProductAccount(account.productExternalRef) ||
      isApexSwitchEnabled) &&
    !!strategyName &&
    productHasStrategies(productSlug) &&
    !productSwitchIsPdfForm(productSlug)

  useEffect(() => {
    !account.isDepositMode &&
      props.fetchFunds(props.user.userid, AccountFundsRequestAction, {
        userId: props.user.userid,
        accountId: props.accountId,
      })
    props.loadSwitchNotifications()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderAccountMenu = () => {
    if (props.currentAccount.isDepositMode) {
      return <AccountDetailsMenuDepositMode />
    }

    if (isKiwisaverAccount(props.currentAccount, true)) {
      return (
        <AccountDetailsMenuKiwisaver
          currentAccount={props.currentAccount}
          user={props.user}
          authToken={props.authToken}
        />
      )
    }

    if (isLifeSaverAccount(props.currentAccount)) {
      return <AccountDetailsMenuLifesaver />
    }

    if (isFuturePlanAccount(props.currentAccount)) {
      return <AccountDetailsMenuFutureplan />
    }

    if (isInvestmentSeries(props.currentAccount)) {
      return <AccountDetailsMenuInvestmentSeries />
    }
    return (
      <AccountDetailsMenu
        currentAccount={props.currentAccount}
        user={user}
        authToken={authToken}
      />
    )
  }

  return (
    <div
      className={cn(
        'account-details-header',
        `account-details-header--${productSlug || 'default'}`
      )}
    >
      <div className="account-details-header__details">
        <p className="text-small m-0">
          {account.simpleProductName}{' '}
          <span className="desktop">- {account.accountNumber}</span>
        </p>
        <div className="account-details-header__name">
          <Typography component="p" variant="h5">
            {account.accountName}
          </Typography>
          {renameModalOpen && (
            <RenameAccountModal
              userId={user.userid}
              account={account}
              authToken={authToken}
              onClose={() => setRenameModalOpen(false)}
            />
          )}
          {ENABLE_RENAME && isManagedFundProductAccount(account) && (
            <IconButton
              color="inherit"
              onClick={(e) => {
                setRenameModalOpen(true)
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
        <p className="text-small mobile m-0">{account.accountNumber}</p>
        {!props.currentAccount.isOwner && (
          <div className="account-details-header__related-account">
            <LinkIcon /> Related Account
          </div>
        )}
      </div>
      {shouldShowStrategySwitch && (
        <InvestmentStrategySwitch
          currentAccount={account}
          className="account-details-header__fund"
        >
          {strategyName}
          <SyncAltIcon className="switch-icon" />
        </InvestmentStrategySwitch>
      )}
      <div className="account-details-header__type">{renderAccountMenu()}</div>
    </div>
  )
}

const mapStateToProps = (
  state: AppState,
  props: AccountDetailsHeaderProps
) => ({
  authToken: state.authorisation.authorisationToken,
  user: state.user,
  currentAccount: getAccountByID(props.accountId, state.accounts.accounts),
  details: getAccountByID(props.accountId, state.accountDetails.accounts),
})

const mapDispatchToProps = (dispatch: Dispatch<AccountDetailsActions>) => ({
  loadSwitchNotifications: bindActionCreators(
    SwitchNotificationsRequestAction,
    dispatch
  ),
  fetchFunds: bindActionCreators(AccountsOverviewFundsRequestAction, dispatch),
  nextStep: (path: string) => dispatch(routerActions.push(path)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(AccountDetailsHeader)
